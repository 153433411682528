import React, { useState, useEffect, useRef} from 'react';
import { enableBodyScroll } from 'body-scroll-lock'  // from 'body-scroll-lock'; // clearAllBodyScrollLocks
import useDisableBodyScroll from '../../hooks/useDisableBodyScroll';
import Loader from '../Loader/Loader';
import '../FormModal/FormModal.scss';
import './ResourcesModal.scss';

const ResourcesEmbedModal = ({setShowResourcesEmbedModal, modalName}) => {

  const modalRef = useRef();
  const closeIconRef = useRef();

  const scrollToTop = () => {
    var ua = window.navigator.userAgent;
    if (ua.indexOf('MSIE ') > 0 ||
    ua.indexOf('Trident/') > 0 ||
    ua.indexOf('Edge/') > 0) {
      modalRef.current.scrollTo(0,0);
    } else {
      modalRef.current.scroll({ 
        top: 0, 
        left: 0, 
        behavior: 'smooth'
      });
    }
  }

  useEffect(()=> {
    scrollToTop();
  }, []);

  useDisableBodyScroll(modalRef);

  const [modalClass, setModalClass] = useState('show');

  const closeModal = (e) => {
      if (
        !e ||
        (modalRef.current && !modalRef.current.contains(e.target)) ||
        (closeIconRef && closeIconRef.current.contains(e.target))
      ) {
        setModalClass('hide');
      }
  }

  const handleModalOnEndAnimation = (e) => {
    if (e.animationName==='exit-form-modal') {
      enableBodyScroll(modalRef.current); /* Must enable before modal disappears */
      setShowResourcesEmbedModal(false);
    }
  }

  const [iframeSrc, setIframeSrc] = useState();

  useEffect(() => {
    const url = modalName === 'employeeHandbook'
      ? 'https://online.flippingbook.com/view/868921545/'
      : modalName === 'benefitsGuidebook'
      ? 'https://online.flippingbook.com/view/1045500661/ ' // OLD: 'https://online.flippingbook.com/view/960518875/'
      : modalName === 'exam'
      ? 'https://redlionhomecare.formstack.com/forms/quiz_sample'
      : modalName === 'tbEducation'
      ? process.env.PUBLIC_URL+'/pdfs/tb-education.pdf'
      : '';
    setIframeSrc(url); 
  }, [modalName]);


  return (
    <div 
      id="form-modal-wrapper" 
      onClick={(e) => closeModal(e)}
      className={modalClass}
      onAnimationEnd={(e) => handleModalOnEndAnimation(e)}
    >
      <div id="form-modal-container" className="exam-modal-container">
        
        <div id="form-modal-content" ref={modalRef}>

          <div ref={closeIconRef} className="icon-close-modal" onClick={(e) => closeModal(e)}></div>


             {/*<div id="form-modal-header">Exam</div>*/}
            
            <div id="form-container">
              <div 
                id="resources-embed-modal-container"
                className={
                  modalName === 'tbEducation' 
                    ? 'page-aspect'
                    : modalName === 'exam'
                      ? 'exam'
                      : ''
                }
              >
                <div>
                  <div>
                    {/* <Loader /> */}
                    {iframeSrc && 
                      <iFrame 
                        id="resources-embed-modal-iframe" 
                        className={modalName === 'tbEducation' ? 'tb-education' : ''}
                        src={iframeSrc} 
                      />
                    }
                  </div>
                </div>
              </div>
              
            </div>

        </div>  
      </div>
    </div>
  )
}

export default ResourcesEmbedModal;
